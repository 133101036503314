import TokenResponseResource from "common/resources/Token/TokenResponseResource";
import { useCallback } from "react";

const roundToTwoDecimals = (num: number): number => {
	return Math.round(num * 100) / 100;
};

export const useMarketCapMilestone = (token?: TokenResponseResource) => {
	const marketcapMilestones = token?.marketCapMilestones?.sort((a, b) => a.milestone - b.milestone);
	const actualMilestone = marketcapMilestones?.find((milestone) => milestone.hasBeenDonated === false);

	const getCurrentLevel = useCallback(() => {
		if (!marketcapMilestones) return 0;
		if (!actualMilestone) return marketcapMilestones.length;
		return actualMilestone.milestone;
	}, [actualMilestone]);

	const getMilestoneProgressPercentage = useCallback(() => {
		if (!marketcapMilestones || !actualMilestone || !token || !token.currentMarketCap) return 0;
		const nextMarketcapMilestone = marketcapMilestones[actualMilestone.milestone];
		if (!nextMarketcapMilestone) return 0;
		return roundToTwoDecimals((token.currentMarketCap / nextMarketcapMilestone.marketcapMilestone) * 100);
	}, [marketcapMilestones, actualMilestone, token]);

	const getKarmaLevel = useCallback(() => {
		if (!marketcapMilestones || !actualMilestone) return "0 / 50";
		if (actualMilestone.milestone === marketcapMilestones.length - 1 && actualMilestone.hasBeenDonated) return `${marketcapMilestones.length} / ${marketcapMilestones.length}`;
		return `${actualMilestone.milestone} / ${marketcapMilestones.length}`;
	}, [marketcapMilestones, actualMilestone]);

	const getNextDonation = useCallback(() => {
		if (!marketcapMilestones || !actualMilestone) return 0;
		const nextMarketcapMilestone = marketcapMilestones[actualMilestone.milestone];
		if (!nextMarketcapMilestone) return 0;
		return nextMarketcapMilestone.donationsInDollars;
	}, [marketcapMilestones, actualMilestone]);

	const getTotalDonated = useCallback(() => {
		if (!marketcapMilestones) return 0;
		return marketcapMilestones
			.filter((milestone) => milestone.hasBeenDonated)
			.reduce((acc, milestone) => {
				if (milestone.donationsInDollars === null) return acc;
				return acc + milestone.donationsInDollars;
			}, 0);
	}, [marketcapMilestones]);

	return {
		getCurrentLevel,
		getKarmaLevel,
		getMilestoneProgressPercentage,
		getNextDonation,
		getTotalDonated,
	};
};
