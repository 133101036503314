import { PropsWithChildren } from "react";
import classes from "./classes.module.scss";
import Modal from "../Modal";
import Typography, { ETypo, ETypoWeight } from "Components/Elements/Typography";
import Logo from "../Logo";
import Button from "Components/Elements/Button";
import KarmaSignatureIcon from "Assets/Icons/KarmaSignatureIcon";
import I18n from "../I18n";

type IProps = PropsWithChildren<{
	isOpen: boolean;
	onClose: () => void;
}>;

export default function AboutUsModal(props: IProps) {

	const onWhitepaperClick = () => {
		window.open("https://karma-fun.gitbook.io/karma.fun-docs", "_blank");
	};

	return (
		<Modal isOpen={props.isOpen} onClose={props.onClose}>
			<div className={classes["root"]}>
				<div className={classes["header"]}>
					<Typography typo={ETypo.H2} className={classes["title"]}>
						<I18n text={I18n.asset.components.about_us_modal.welcome} />
					</Typography>
					<Logo className={classes["logo"]} />
				</div>

				<div className={classes["text-container"]}>
					<Typography typo={ETypo.BODY_18} weight={ETypoWeight.REGULAR}>
						<I18n text={I18n.asset.components.about_us_modal.description_1} />
					</Typography>

					<Typography typo={ETypo.BODY_18} weight={ETypoWeight.REGULAR}>
						<I18n text={I18n.asset.components.about_us_modal.description_2} />
					</Typography>
				</div>

				<div className={classes["footer"]}>
					<div className={classes["button-container"]}>
						<Button className={classes["button"]} onClick={onWhitepaperClick}>
							<I18n text={I18n.asset.components.about_us_modal.whitepaper_button} />
						</Button>

						<Typography typo={ETypo.SUBTITLE_32} className={classes["signature"]}>
							<I18n text={I18n.asset.components.about_us_modal.signature} />
							&nbsp;
							<span className={classes["signature-color"]}>
								<I18n text={I18n.asset.components.about_us_modal.signature_color} />
							</span>
						</Typography>
					</div>

					<KarmaSignatureIcon />
				</div>
			</div>
		</Modal>
	);
}
