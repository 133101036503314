import Logo from "../Logo";
import classes from "./classes.module.scss";
import I18n from "../I18n";
import Typography, { ETypo } from "Components/Elements/Typography";
import AboutUsIcon from "Assets/Icons/AboutUsIcon";
import DonationRequestIcon from "Assets/Icons/DonationRequestIcon";
import TwitterLogo from "Assets/Images/twitter-logo.png";
import { useCallback, useState } from "react";
import BurgerMenuIcon from "Assets/Icons/BurgerMenuIcon";
import MobileMenu from "./MobileMenu";
import AboutUsModal from "../AboutUsModal";

export default function Header() {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const toggleModal = useCallback(() => setIsModalOpen((prev) => !prev), []);
	const toggleMenu = useCallback(() => setIsMenuOpen((prev) => !prev), []);

	const onAboutUsClick = useCallback(() => toggleModal(), [toggleModal]);

	const onDonationRequestClick = useCallback(
		() => window.open(" https://docs.google.com/forms/d/e/1FAIpQLSfPP5zf0QihUJBSnt1a9alSCqxSnKfBL-huiszFMQB9W8Wzhw/viewform?usp=header"),
		[],
	);

	const onTwitterLinkClick = useCallback(() => window.open("https://x.com/KarmaDotFun", "_blank"), []);

	const onBurgerMenuClick = useCallback(() => toggleMenu(), [toggleMenu]);

	return (
		<header className={classes["root"]}>
			<div className={classes["container"]}>
				<Logo className={classes["logo"]} />

				<div className={classes["navbar"]}>
					<div className={classes["item"]} onClick={onAboutUsClick}>
						<AboutUsIcon />
						<Typography typo={ETypo.H4}>
							<I18n text={I18n.asset.components.header.items.about_us} />
						</Typography>
					</div>

					<div className={classes["item"]} onClick={onDonationRequestClick}>
						<DonationRequestIcon />
						<Typography typo={ETypo.H4}>
							<I18n text={I18n.asset.components.header.items.donation_request} />
						</Typography>
					</div>

					<div className={classes["item"]} onClick={onTwitterLinkClick}>
						<img src={TwitterLogo} alt="twitter logo" className={classes["twitter-logo"]} />
						<Typography typo={ETypo.H4}>
							<I18n text={I18n.asset.components.header.items.twitter_link} />
						</Typography>
					</div>
				</div>

				<div className={classes["button-container"]}>
					<div className={classes["burger-menu"]} onClick={onBurgerMenuClick}>
						<BurgerMenuIcon />
					</div>
				</div>
			</div>

			<MobileMenu
				isOpen={isMenuOpen}
				onClose={toggleMenu}
				onAboutUsClick={onAboutUsClick}
				onDonationRequestClick={onDonationRequestClick}
				onTwitterLinkClick={onTwitterLinkClick}
			/>

			<AboutUsModal isOpen={isModalOpen} onClose={toggleModal} />
		</header>
	);
}
