import I18n from "Components/Materials/I18n";
import Layout from "../Layout";
import classes from "./classes.module.scss";
import TotalDonation from "Components/Materials/TotalDonation";
import HowItWorks from "./HowItWorks";
import CollectionCard from "Components/Materials/CollectionCard";
import UpcomingCollectionCard from "Components/Materials/UpcomingCollectionCard";
import { useToken } from "hooks/useTokens";
import MarketCapMilestoneResponseResource from "common/resources/MarketCapMilestone/MarketCapMilestoneResponseResource";

function getTokenTotalDonation(marketcapMilestones: MarketCapMilestoneResponseResource[]) {
	return marketcapMilestones
		.filter((milestone) => milestone.hasBeenDonated)
		.reduce((acc, milestone) => {
			if (milestone.donationsInDollars === null) return acc;
			return acc + milestone.donationsInDollars;
		}, 0);
}

export default function Home() {
	const { tokens } = useToken();
	const totalDonation = tokens.reduce((acc, token) => {
		if (!token.marketCapMilestones) return acc;
		const tokenTotalDonation = getTokenTotalDonation(token.marketCapMilestones);
		return acc + tokenTotalDonation;
	}, 0);

	return (
		<Layout tabTitle={I18n.asset.pages.home.tab_title} className={classes["root"]}>
			<TotalDonation total={totalDonation} />

			<div className={classes["collection-cards"]}>
				{tokens.map((token) => (
					<CollectionCard key={token.id} token={token} />
				))}
				<UpcomingCollectionCard />
				<UpcomingCollectionCard />
			</div>

			<HowItWorks />
		</Layout>
	);
}
